<script>
import IconBase from "@/components/helpers/IconBase";
import IconError from "@/components/icons/IconError";

export default {
  components: { IconError, IconBase },
};
</script>

<template>
  <div class="balance-refill-error">
    <div class="balance-refill-error__status">
      <div class="balance-refill-error__icon">
        <icon-base
          icon-name="icon-error"
          icon-fill="#EB5757"
          height="40"
          width="40"
          view-box-width="40"
          view-box-height="40"
        >
          <icon-error />
        </icon-base>
      </div>
      <div class="balance-refill-error__info">
        <h4>Что-то пошло не так...</h4>
        <p>Обратитесь к технической поддержке</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-refill-error {
  padding: $space-xxl;

  &__status {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 12px;
  }

  &__info {
    h4 {
      @include text-1;
      color: $dark-primary;
      margin-bottom: $space-xs;
    }

    p {
      @include body-1;
      color: $dark-sixth;
    }
  }

  @media (max-width: 576px) {
    padding: $space-xxl $space-l;
  }
}
</style>
